import axios from "axios";
import { useEffect, useState } from "react";
import { Card, Space, Spin, Typography } from "antd";
import ImageGrid from "./MarketingImageGrid";
import { FolderOutlined } from "@ant-design/icons";
type Api_ImageData = {
  [key: string]: { width: number; height: number } | Api_ImageData;
};

type ApiResponse = {
  Images: Api_ImageData;
  Videos: Api_ImageData;
};

export type ImageData = {
  width: number;
  height: number;
  altText: string;
  imageUrl: string;
};

export type VideoData = {
  videoUrl: string;
  width: number;
  height: number;
};

const BASE_URL = "https://cdn-marketing.herozonevr.com/";

export const Marketing = () => {
  const [images, setImages] = useState<
    {
      folderName: string;
      images: ImageData[];
    }[]
  >([]);
  const [videos, setVideos] = useState<
    { folderName: string; videos: VideoData[] }[]
  >([]);
  useEffect(() => {
    fetchImageList();
  }, []);

  const fetchImageList = async () => {
    try {
      const { data } = await axios.get<ApiResponse>(
        "/api/marketing_images",
        {}
      );
      const groupedImages = flattenImageData(data.Images || {});

      setImages(groupedImages);
      setVideos(flattenVideoData(data.Videos || {}));
    } catch (error) {
      console.error("Error fetching images", error);
    }
  };

  const flattenImageData = (
    imageData: Api_ImageData,
    parentKey: string = ""
  ): {
    folderName: string;
    images: ImageData[];
  }[] => {
    let groupedImages: {
      folderName: string;
      images: ImageData[];
    }[] = [];

    Object.keys(imageData).forEach((key) => {
      const value = imageData[key];
      if (
        typeof value === "object" &&
        "width" in value &&
        "height" in value &&
        typeof value.width === "number" &&
        typeof value.height === "number"
      ) {
        const imageUrl = `${BASE_URL}Images/${parentKey}${key}`;
        const folderName = parentKey.split("/").filter(Boolean).pop() || key;
        const existingFolder = groupedImages.find(
          (folder) => folder.folderName === folderName
        );

        const imageObject = {
          imageUrl,
          altText: key,
          width: value.width,
          height: value.height,
        };

        if (existingFolder) {
          existingFolder.images.push(imageObject);
        } else {
          groupedImages.push({
            folderName,
            images: [imageObject],
          });
        }
      } else if (typeof value === "object") {
        groupedImages = groupedImages.concat(
          flattenImageData(value as Api_ImageData, `${parentKey}${key}/`)
        );
      }
    });

    return groupedImages;
  };
  const flattenVideoData = (
    videoData: Api_ImageData | VideoData,
    parentKey: string = ""
  ): {
    folderName: string;
    videos: VideoData[];
  }[] => {
    let groupedVideos: {
      folderName: string;
      videos: VideoData[];
    }[] = [];

    Object.keys(videoData).forEach((key) => {
      const value = (videoData as any)[key];
      if (
        typeof value === "object" &&
        "width" in value &&
        "height" in value &&
        typeof value.width === "number" &&
        typeof value.height === "number"
      ) {
        // Construct the video URL
        const videoUrl = `${BASE_URL}Videos/${parentKey}${key}`;
        const folderName = parentKey.split("/").filter(Boolean).pop() || key;

        const existingFolder = groupedVideos.find(
          (folder) => folder.folderName === folderName
        );

        const videoObject: VideoData = {
          videoUrl,
          width: value.width || 300,
          height: value.height + 50 || 300, // Add 50px for the video title
        };

        if (existingFolder) {
          existingFolder.videos.push(videoObject);
        } else {
          groupedVideos.push({
            folderName,
            videos: [videoObject],
          });
        }
      } else if (typeof value === "object") {
        groupedVideos = groupedVideos.concat(
          flattenVideoData(value as VideoData, `${parentKey}${key}/`)
        );
      }
    });

    return groupedVideos;
  };
  if (!images || images.length === 0) return <Spin size="large" />;
  return (
    <div style={{ width: "85%" }}>
      <Space style={{ width: "100%" }} direction="vertical">
        <Typography.Title level={2}>Marketing Assets</Typography.Title>
        <Typography.Link
          href="https://drive.google.com/drive/folders/1ts15cUDgrhbAMRXsa3DyjVTNKY2QEeSk?usp=drive_link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontSize: "1.1em" }}
        >
          <FolderOutlined style={{ fontSize: "1.1em" }} /> Game Guides
        </Typography.Link>
        <br />

        {videos.map((folder, index) => (
          <Card title={folder.folderName} bordered={false} key={index}>
            <ImageGrid images={folder.videos} />
          </Card>
        ))}
        {images.map((folder, index) => (
          <Card title={folder.folderName} bordered={false} key={index}>
            <ImageGrid images={folder.images} />
          </Card>
        ))}
      </Space>
    </div>
  );
};
